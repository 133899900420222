@import './../../../assets/style';

.card {
  position: relative;

  width: 317px;
  height: 100%;
  padding: 24px 32px 40px;

  background-color: var(--brand_3);
  box-shadow: 0 24px 10px rgba(0, 0, 0, 0.01), 0 13px 8px rgba(0, 0, 0, 0.04), 0 6px 6px rgba(0, 0, 0, 0.07), 0 1px 3px rgba(0, 0, 0, 0.08), 0 0 0 rgba(0, 0, 0, 0.08);
  border-radius: 16px;

  @include media(max, xl) {
    width: 200px;
    padding: 8px 16px 24px;
  }

  @include media(max, lg) {
    width: 100%;
  }

  &:before {
    content: '';
    position: absolute;
    top: 50%;
    right: -60px;
    transform: translateY(-50%);

    width: 40px;
    height: 40px;

    background: url("./../../../assets/images/arrow-red.svg") no-repeat center center/100%;

    @include media(max, 1279px) {
      right: -45px;
    }

    @include media(max, xl) {
      right: -40px;
      width: 30px;
      height: 30px;
    }

    @include media(max, lg) {
      right: -10px;
      width: 30px;
      height: 30px;
    }

    @include media(max, 640px) {
      top: unset;
      right: unset;
      bottom: -57px;
      left: 50%;
      transform: translateX(-50%) rotate(90deg);
      width: 40px;
      height: 40px;
    }
  }
}

.image {
  position: relative;
  z-index: 10;

  width: 192px;
  height: 352px;
  margin-bottom: 16px;

  object-fit: cover;

  @include media(max, xl) {
    width: 121px;
    height: 222px;
    margin-bottom: 8px;
  }

  @include media(max, lg) {
    margin-bottom: 8px;
  }
}

.title {
  margin-bottom: 8px;
  color: var(--brand_1);

  &::first-letter {
    text-transform: uppercase;
  }
}

.text {
  height: 38px;
  color: var(--text);
  text-transform: lowercase;

  overflow: hidden;
  display: -webkit-box;
  -webkit-line-clamp: 2;
  -webkit-box-orient: vertical;

  &::first-letter {
    text-transform: uppercase;
  }
}
