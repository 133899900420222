@import './../../../../assets/style';

.content {
  padding: 57px 78px 102px;

  @include media(max, xl) {
    padding: 40px 58px 358px;
  }

  @include media(max, lg) {
    padding: 32px 10px 358px;
  }

  background: url("./../../../../assets/images/arrow-popup-1.svg") no-repeat right 470px top 233px/92px 77px,
  url("./../../../../assets/images/arrow-popup-2.svg") no-repeat right 215px top 148px/80px 90px;

  @include media(max, xl) {
    background: url("./../../../../assets/images/arrow-popup-tablet-2.svg") no-repeat right 180px top 215px/66px  115px,
    url("./../../../../assets/images/arrow-popup-tablet-1.svg") no-repeat right 370px bottom 250px/95px 17px;
  }

  @include media(max, lg) {
    background: unset;
  }
}

.title {
  max-width: 547px;
  margin-bottom: 40px;

  color: var(--brand_1);

  @include media(max, xl) {
    max-width: 370px;
    margin-bottom: 24px;
  }

  @include media(max, lg) {
    max-width: unset;
  }
}

.list {
  display: grid;
  grid-auto-rows: var(--minmax);
  row-gap: 40px;
  max-width: 263px;

  @include media(max, xl) {
    row-gap: 24px;
  }

  @include media(max, lg) {
    max-width: unset;
    margin-bottom: 21px;
  }
}

.item {
  position: relative;
  padding-left: 26px;

  color: var(--dark);

  &::before {
    content: "";
    position: absolute;
    top: 0;
    left: 0;

    width: 18px;
    height: 15px;

    background: url("./../../../../assets/images/ellipse.svg") no-repeat center center/100%;
  }
}

.wrap {
  position: absolute;
  right: 78px;
  bottom: 29px;

  @include media(max, xl) {
    right: 32px;
    bottom: 16px;
  }

  @include media(max, lg) {
    display: flex;
    flex-direction: column;
    align-items: center;
    position: unset;
  }
}

.logo {
  width: 174px;
  height: 75px;
  margin-bottom: 5px;
}

.buttons {
  display: grid;
  grid-auto-flow: column;
  grid-auto-columns: var(--minmax);
  column-gap: 4px;
}

.button {
  width: 86px;
  height: 26px;
}

.imageTel1 {
  position: absolute;
  left: 325px;
  bottom: 38px;

  width: 246px;
  height: 359px;

  @include media(max, xl) {
    left: 43px;
    bottom: 11px;

    width: 218px;
    height: 305px;
  }

  @include media(max, lg) {
    left: -20px;
    bottom: 24px;

    width: 142px;
    height: 227px;
  }
}

.imageTel2 {
  position: absolute;
  right: 299px;
  bottom: 121px;

  width: 154px;
  height: 335px;

  @include media(max, xl) {
    left: 333px;
    bottom: 85px;
    z-index: 100;

    width: 131px;
    height: 305px;
  }

  @include media(max, lg) {
    left: calc(50% - 48px);
    bottom: 68px;

    width: 97px;
    height: 211px;
  }
}

.imageTel3 {
  position: absolute;
  top: 65px;
  right: 23px;

  width: 229px;
  height: 362px;

  @include media(max, xl) {
    top: 107px;
    right: 15px;

    width: 195px;
    height: 308px;
  }

  @include media(max, lg) {
    top: unset;
    right: -15px;
    bottom: 88px;

    width: 135px;
    height: 229px;
  }
}
