@import './../../../../assets/style';

.list {
  display: grid;
  grid-auto-flow: column;
  grid-auto-columns: var(--minmax);
  justify-content: center;
  align-items: center;
  column-gap: 64px;

  @include media(max, xl) {
    column-gap: 24px;
  }

  @include media(max, lg) {
    column-gap: 24px;
  }
}

.item {
  display: flex;
  flex-direction: column;
  align-items: center;
}

.image {
  width: 208px;
  height: 198px;
  margin-bottom: var(--sp-xs);

  @include media(max, xl) {
    width: 130px;
    height: 136px;
  }
}
