@import './assets/style';

*, ::before, ::after {
    box-sizing: border-box;
}

body {
    min-width: 320px;
    font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
}

body,h1,h2,h3,h4,h5,h6,p,nav,ul,ol,dl,dd,dt,fieldset,button {
    padding: 0;
    margin: 0;
}

button,
input,
optgroup,
select,
textarea {
    font-family: inherit;
    font-size: 100%;
    line-height: 1.15;
    margin: 0;

&:focus {
     outline: 1px solid transparent;
 }
}

input,textarea {

&:placeholder-shown {
     text-overflow: ellipsis;
 }
}

button,
input {
    overflow: visible
}

button,
select {
    text-transform: none
}

button,
[type="button"],
[type="reset"],
[type="submit"] {
    -webkit-appearance: button
}

button::-moz-focus-inner,
[type="button"]::-moz-focus-inner,
[type="reset"]::-moz-focus-inner,
[type="submit"]::-moz-focus-inner {
    border-style: none;
    padding: 0
}

button:-moz-focusring,
[type="button"]:-moz-focusring,
[type="reset"]:-moz-focusring,
[type="submit"]:-moz-focusring {
    outline: 1px dotted ButtonText
}


[type="number"]::-webkit-inner-spin-button,
[type="number"]::-webkit-outer-spin-button {
    height: auto
}

[type="search"] {
    -webkit-appearance: textfield;
    outline-offset: -2px
}

[type="search"]::-webkit-search-decoration {
    -webkit-appearance: none
}

::-webkit-file-upload-button {
    -webkit-appearance: button;
    font: inherit
}

template {
    display: none
}

html {
    line-height: 1.15;
    -webkit-text-size-adjust: 100%;
}

input::-ms-clear {
    display: none;
}

input:-webkit-autofill,
input:-webkit-autofill:hover,
input:-webkit-autofill:focus {
    -webkit-box-shadow: 0 0 0px 1000px white inset;
}

button {
    border: none;
    background-color: transparent;
    cursor: pointer;
}

progress {
    vertical-align: baseline;
}

a {
    color: inherit;
    cursor: pointer;
}

a,
a:hover,
a:visited {
    text-decoration: none;
}

menu, ul, li {
    list-style: none;
}

img {
    vertical-align: bottom;
}

*::selection {
    background-color: #65f5be;
    color: #016b58;
}

