@import './../../../assets/style';

.inputSearch {
  max-width: 894px;
  width: 100%;
}

.wrap {
  position: relative;
  margin-bottom: 8px;

  width: 100%;
  height: 27px;
}

.input {
  width: 100%;
  height: 100%;
  padding-bottom: 8px;

  color: var(--dark);

  background-color: transparent;
  border: none;
  border-bottom: 1px solid var(--interface);

  transition: border-bottom 500ms ease;

  &::placeholder {
    color: var(--text);
  }

  &:focus {
    border-bottom: 1px solid var(--dark);
  }
}

.inputValue {
  border-bottom: 1px solid var(--dark);
}

.icon {
  position: absolute;
  top: 0;
  right: 10px;

  display: flex;
  width: 19px;
  height: 19px;
}
