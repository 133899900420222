@font-face {
  font-family: DINPro-Bold;
  font-display: swap;
  src: url(./../fonts/DINPro-Bold.woff) format("woff2"),url(./../fonts/DINPro-Bold.woff) format("woff");
  font-weight: 700;
  font-style: normal
}

@font-face {
  font-family: DINPro-Medium;
  font-display: swap;
  src: url(./../fonts/DINPro-Medium.woff) format("woff2"),url(./../fonts/DINPro-Medium.woff) format("woff");
  font-weight: 500;
  font-style: normal
}

@font-face {
   font-family: DINPro-Regular;
   font-display: swap;
   src: url(./../fonts/DINPro-Regular.woff) format("woff2"),url(./../fonts/DINPro-Regular.woff) format("woff");
   font-weight: 400;
   font-style: normal
}

:root {
  --supercaption: 48px/1.1 'DINPro-Medium';
  --h1: 32px/1.2 'DINPro-Medium';
  --h2: 22px/1.2 'DINPro-Medium';
  --p1: 22px/1.2 'DINPro-Regular';
  --p2: 16px/1.2 'DINPro-Medium';
  --p3: 16px/1.2 'DINPro-Regular';
  --caption-1: 12px/1.3 'DINPro-Bold';
  --caption-2: 12px/1.2 'DINPro-Regular';

  @include media(max, xl) {
    --supercaption: 32px/1.2 'DINPro-Medium';
    --h1: 22px/1.2 'DINPro-Medium';
    --h2: 20px/1.2 'DINPro-Medium';
  }

  @include media(max, lg) {
    --supercaption: 22px/1.1 'DINPro-Medium';
    --h1: 20px/1.2 'DINPro-Medium';
  }
}

.supercaption {
  font: var(--supercaption);
  color: var(--brand_1)
}

.h1 {
  font: var(--h1);
}

.h2 {
  font: var(--h2);
}

.p1 {
  font: var(--p1);
}

.p2 {
  font: var(--p2);
}

.p3 {
  font: var(--p3);
}

.caption_1 {
  font: var(--caption-1);
}

.caption_2 {
  font: var(--caption-2);
}
