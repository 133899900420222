@import './../../../assets/style';

.buttonBack {
  display: grid;
  grid-auto-flow: column;
  grid-auto-columns: var(--minmax);
  align-items: center;
  column-gap: 8px;

  color: var(--iconography);

  span {
    @include media(max, lg) {
      display: none;
    }
  }

  &:hover {
    color: var(--brand_1);
  }
}

.icon {
  display: flex;
  width: 16px;
  height: 16px;

  @include media(max, lg) {
    width: 24px;
    height: 24px;
  }
}
