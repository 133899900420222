:root {
  --brand_1: #E2001A;
  --brand_2: #808285;
  --brand_3: #FFFFFF;
  --brand_4: #F6F6F6;
  --text: #666666;
  --iconography: #767676;
  --interface: #D7D7D7;
  --dark: #333333;
  --popup-bg: #30303080;
}
