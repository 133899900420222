@import './../assets/style';

.app {
  display: flex;
  flex-direction: column;
  align-items: center;
  min-height: 100vh;
  padding-top: var(--sp-m);
}

.logo {
  width: 163px;
  height: 56px;
  margin-bottom: var(--sp-l);

  @include media(max, xl) {
    width: 140px;
    height: 40px;
  }

  @include media(max, lg) {
    width: 93px;
    height: 32px;
  }

  img {
    display: block;
    width: 100%;
    height: auto;
  }
}
