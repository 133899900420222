@import './../../assets/style';

.main {
  flex-grow: 1;
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 100%;
}

.title {
  max-width: 630px;
  margin-bottom: var(--sp-m);
  text-align: center;

  @include media(max, xl) {
    max-width: 435px;
  }

  @include media(max, lg) {
    max-width: 295px;
    margin-bottom: 24px;
    font-family: DINPro-Bold;
  }
}

.text {
  margin-bottom: var(--sp-m);
  color: var(--dark);
  text-align: center;

  @include media(max, xl) {
    margin-bottom: var(--sp-xs);
  }

  @include media(max, lg) {
    font-family: DINPro-Bold;
  }
}

.wrap {
  max-width: 894px;
  width: 100%;
  margin-top: var(--sp-l);

  @include media(max, lg) {
    margin-top: var(--sp-xl);
  }
}
